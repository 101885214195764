<template>
  <div
    v-if="wins.length"
    :id="containerId"
    class="relative"
  >
    <HorizontalScroller
      :items-length="wins.length"
      :snapping="snapping"
      @dragging="handleToggleDragging"
    >
      <div
        v-for="item in wins"
        :key="item.keyId"
        class="snap-start hardware-accelerate cards-wins"
      >
        <CardWinMain
          v-bind="item"
          :is-top-win="isTopWin"
          @select="handleSelectItem(item)"
        />
      </div>
    </HorizontalScroller>
  </div>
</template>

<script setup>
import uuid from 'uuid';

defineProps({
  wins: {
    type: Array,
    default: null,
  },
  isTopWin: {
    type: Boolean,
    default: false,
  },
  snapping: {
    type: Boolean,
    default: true,
  },
});

const emits = defineEmits(['select',]);

const containerId = `container-${uuid()}`;
const isDragging = ref(false);

function handleToggleDragging(newVal) {
  isDragging.value = newVal;
}

function handleSelectItem(item) {
  if (!isDragging.value) {
    emits('select', item);
  }
}
</script>

<style scoped lang="scss">
.cards-wins {
  width: 102px;
  margin-right: unset; // Required due to tailwind being naff.
}
</style>
