<template>
  <span class="animate-fade-in leading-none my-0 pt-0.25 lg:pt-0.5 inline-flex font-bold gap-1 place-content-center">
    <tippy v-if="isHouse" placement="bottom" class="m-0 p-0 flex">
      <button class="bg-green-dark text-green-house-light h-2.5 py-[0.5px] px-[3px] m-auto -rotate-12 text-6xs leading-house-money rounded">
        HM
      </button>
      <template #content>
        <div class="flex font-bold gap-1">
          <div class="bg-green-dark text-green-house-light py-1 px-2 -rotate-12 text-sm leading-1 rounded-lg">
            HM
          </div>
          <p class="m-auto mx-0">House Money</p>
        </div>
        <div class="pt-2">
          Some gameplay by streamers or affiliates uses promotional funds for demonstration. At MetaWin, we value transparency and honesty in showcasing our games.
        </div>
      </template>
    </tippy>
    <p
      class="leading-3"
      :class="[
        textSize ? textSize : 'text-3xs',
        isTopWin ? 'gradient-gold' : 'text-green-500',
      ]"
    >
      {{ prizeDisplay }}
    </p>
  </span>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useCryptoStore } from '@/store/crypto';
import { useUiStore } from '@/store/ui';

const { prizeValue, isTopWin, textSize, isHouse, } = defineProps({
  prizeValue: {
    type: Object,
    default: null,
  },
  isTopWin: {
    type: Boolean,
    default: false,
  },
  textSize: {
    type: String,
    default: null,
  },
  isHouse: {
    type: Boolean,
    default: false,
  },
});

const uiStore = useUiStore();
const {
  uiSettings,
} = storeToRefs(uiStore);

const cryptoStore = useCryptoStore();
const {
  rates,
} = storeToRefs(cryptoStore);

const { $formatMoney, $truncateNumber, $toBigNumber, } = useNuxtApp();

const uiSettingsPrizeValueCurrency = computed(() => uiSettings.value?.prizeValueCurrency || 'Crypto');
const prizeDisplay = computed(() => {
  if (!prizeValue || !prizeValue.value || !prizeValue.currency) { return ''; }
  let truncated = String($truncateNumber(prizeValue.value, 6));
  if (truncated.length > 6) { truncated = truncated.slice(0, 8); }

  const displayCrypto = `${truncated} ${prizeValue.currency}`;

  if (uiSettingsPrizeValueCurrency.value === 'Crypto') {
    return displayCrypto;
  } else {
    if (prizeValue?.valueBase) {
      return prizeValue.valueBase < 100 ? `${$formatMoney(prizeValue.valueBase, 'USD', true)}` : `$${$truncateNumber(prizeValue.valueBase, 0, true)}`;
    }

    if (prizeValue?.currency === 'USDC') { return prizeValue.value < 100 ? `${$formatMoney(prizeValue.value, 'USD', true)}` : `$${$truncateNumber(prizeValue.value, 0, true)}`; }

    if (!rates.value) { return displayCrypto; }
    const rateUsd = rates.value[prizeValue.currency]?.USD || null;
    if (!rateUsd) { return displayCrypto; }
    const rateUsdBn = $toBigNumber(rateUsd);
    const valCryptoBn = $toBigNumber(prizeValue.value);
    const valUsd = valCryptoBn.multipliedBy(rateUsdBn).decimalPlaces(2).toNumber();

    if (valUsd < 100) {
      return $formatMoney(valUsd, 'USD', true);
    } else {
      return `$${$truncateNumber(valUsd, 0, true)}`;
    }
  }
});
</script>
