<template>
  <article class="flex flex-col rounded-lg bg-slate-900 px-0.5 pt-0.5 pb-1.5 overflow-hidden cursor-pointer transition-all duration-200 relative group/card">
    <div class="flex flex-col items-center" @click.prevent="cardAction()">
      <figure class="w-full aspect-square rounded-md overflow-hidden relative z-0 -top-[0.5px] animate-fade-in border-2 border-transparent">
        <ImageLoader
          :classes="['object-cover h-full w-full block relative z-10']"
          card-dark
          :image-url="$imgSrcResize(imageUrlDisplay, 200, 200)"
          :width="200"
          :height="200"
          loading-type="lazy"
        />
      </figure>

      <div class="flex flex-nowrap items-center justify-center w-full mt-1">
        <div class="flex items-center overflow-hidden mr-1 p-1 -my-1">
          <UserAvatar
            v-if="winner?.avatarUrl"
            :url="winner.avatarUrl"
            :country="winner?.currentCountry || winner?.country"
            size="2xs"
            class="flex items-center shrink-0 transition-all rounded-full mr-1"
            img-class="w-3 h-3"
            :level="level"
          />
          <h3 class="font-bold truncate leading-tight text-3xs">
            {{ winner?.displayName }}
          </h3>
        </div>
      </div>

      <CurrencyDisplayTopWin
        v-if="!isMobileDevice"
        :prize-value="prizeValue"
        :is-top-win="isTopWin"
        :is-house="isHouse"
      />
    </div>

    <CurrencyDisplayTopWin
      v-if="isMobileDevice"
      :prize-value="prizeValue"
      :is-top-win="isTopWin"
      :is-house="isHouse"
    />
  </article>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useMinigamesStore } from '@/store/minigames';
import { useAuthStore } from '@/store/auth';
import { useDeviceStore } from '@/store/device';
import { useUserAuth } from '~/composables/useUserAuth';

const props = defineProps({
  source: {
    type: String,
    default: null,
  },
  sweepstakeId: {
    type: Number,
    default: null,
  },
  displayName: {
    type: String,
    default: null,
  },
  avatarUrl: {
    type: String,
    default: null,
  },
  data: {
    type: Object,
    default: null,
  },
  prize: {
    type: Object,
    default: null,
  },
  country: {
    type: String,
    default: null,
  },
  imageUrl: {
    type: String,
    default: null,
  },
  isTopWin: {
    type: Boolean,
    default: false,
  },
  isHouse: {
    type: Boolean,
    default: false,
  },
  level: {
    type: Number,
    default: 0,
  },
});

const emits = defineEmits(['select',]);

const { $imgSrcResize, } = useNuxtApp();

const authStore = useAuthStore();
const {
  isUserLogged,
} = storeToRefs(authStore);

const deviceStore = useDeviceStore();
const {
  isMobileDevice,
} = storeToRefs(deviceStore);

const minigamesStore = useMinigamesStore();
const {
  openGameDirect,
} = minigamesStore;

const { handleOpenRegisterModal, } = useUserAuth();

const imageUrlDisplay = computed(() => props?.source === 'game' ? props.data?.imageUrl : props.imageUrl);
const winner = computed(() => {
  return {
    displayName: props.displayName,
    avatarUrl: props.avatarUrl,
    country: props.country,
  };
});
const prizeValue = computed(() => {
  if (props?.source === 'game') {
    return {
      value: props.data?.win,
      valueBase: props.data?.winBase,
      currency: props.data?.currencyCode,
    };
  } else if (props.prize.type === 'NFT') {
    return {
      value: props.prize?.value,
      valueBase: props.prize?.baseAmount,
      currency: 'ETH',
    };
  } else {
    return {
      value: props.prize?.amount || props.prize?.value,
      valueBase: props.prize?.baseAmount,
      currency: props.prize?.symbol || props.prize?.currencyCode,
    };
  }
});

function cardAction() {
  if (props?.source === 'game') {
    if (!isUserLogged.value) {
      handleOpenRegisterModal();
      return;
    }
    // In house Games in floating container
    if (
      props.data?.minigame
      || (
        props.data?.id
        && (
          props.data?.provider === 'Arena'
          || ['HiLo', 'BigWheel',].includes(props.data?.type)
        )
      )
    ) {
      return openGameDirect(
        {
          id: props.data?.id,
          name: props.data?.name,
          currencyCode: props.data?.currencyCode,
          provider: 'Arena',
        },
        false,
        'floating'
      );
    } else if (props.data?.slug && props.data?.provider !== 'Arena') {
      // Pragmatic Play Live Casino and Instnat Games
      return navigateTo(
        props.data?.type === 'LiveCasino'
          ? `/live-games/${props.data.slug}`
          : `/games/${props.data.slug}`
      );
    }
  } else if (props?.sweepstakeId) {
    return navigateTo(`/competition/${props.sweepstakeId}`);
  } else {
    emits('select');
  }
}
</script>
