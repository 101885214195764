<template>
  <div
    v-element-visibility="onVisibilityChange"
    class="mb-1 mt-3"
    :class="{'!mb-4': placement === 'game'}"
  >
    <div class="flex items-center justify-between mb-1">
      <h2
        class="category-title"
        :class="[isUserLogged ? 'font-black text-xl' : 'font-black md:font-normal text-base']"
      >
        {{ isUserLogged ? 'Live Wins' : 'Hall of Fame' }}
      </h2>

      <div v-if="placement !== 'game' && isUserLogged" class="flex items-center">
        <button
          v-for="item in modes"
          :key="item.id"
          theme="loadmore"
          class="text-xs lg:text-sm py-1 px-1.5 lg:px-2 lg:mx-0.5 rounded-md lg:hover:text-white transition-all duration-100"
          :class="[
            item.active ? 'text-white bg-slate-900 ring-2 ring-slate-100/10' : 'text-slate-100'
          ]"
          @click.prevent.stop="toggleMode(item.id)"
        >
          {{ item.label }}
        </button>
      </div>
    </div>

    <div v-if="activeMode.id === 'live'">
      <ListWinsScroller
        :key="activeMode.id"
        :wins="liveWinsDisplay"
        :snapping="false"
      />
    </div>
    <div v-else>
      <ListWinsScroller
        :key="activeMode.id"
        is-top-win
        :wins="topWinsDisplay"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import uuid from 'uuid';
import { vElementVisibility } from '@vueuse/components';
import { useAuthStore } from '@/store/auth';
import { useWebsocketStore } from '@/store/websocket';
import { useCryptoStore } from '@/store/crypto';

export default defineComponent({
  name: 'ListWinsMain',
  directives: {
    'element-visibility': vElementVisibility,
  },
  inject: ['mq',],
  props: {
    placement: {
      type: String,
      default: '',
    },
    minWinUsd: {
      type: Number,
      default: 1,
    },
    subCategory: {
      type: String,
      default: 'all',
    },
  },
  setup() {
    const websocketStore = useWebsocketStore();
    return { websocketStore, };
  },
  data() {
    return {
      modes: [
        {
          label: 'Live',
          id: 'live',
          icon: 'icon-ico-flame',
          active: true,
        },
        {
          label: 'Top',
          id: 'top',
          active: false,
        },
        {
          label: 'Month',
          id: 'month',
          active: false,
        },
        {
          label: 'Week',
          id: 'week',
          active: false,
        },
        {
          label: '24h',
          id: 'day',
          active: false,
        },
      ],
      initiated: false,
      subscriptions: [
        'Activity:Games',
        'Sweepstakes',
      ],
      liveWins: [],
      topWins: [],
      maxWins: 20,
      loadMaxWins: 60,
      initialGameWinners: [],
      initialCompetitionWinners: [],
      displayBreakpointConfig: {
        xxxxs: 3,
        xxxs: 4,
        xxs: 4,
        xs: 4,
        ssm: 5,
        sm: 6,
        md: 8,
        lg: 10,
        xl: 9,
        xxl: 11,
        xxxl: 12,
        xxxxl: 15,
        xxxxxl: 16,
      },
      isVisible: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['isUserLogged',]),
    ...mapState(useCryptoStore, ['rates',]),
    screenSize() {
      return this.mq.current;
    },
    liveWinsDisplay() {
      return this.liveWins.map((item) => {
        return {
          ...item,
          keyId: item.id || item.prize.id || uuid.v4(),
          timestamp: item.timestamp || item.date,
          imageUrl: item.data?.imageUrl || item.prize?.imageUrl || item.sweepstakeImageUrl,
          source: item.sweepstakeId ? 'competition' : 'game',
        };
      });
    },
    topWinsDisplay() {
      const newTopWins = this.topWins.map((item) => {
        return {
          ...item,
          keyId: item.date,
          timestamp: item.date,
          source: item.type === 'Sweepstake' ? 'competition' : 'game',
          sweepstakeId: item.type === 'Sweepstake' ? item.id : null,
          displayName: item.user?.displayName,
          avatarUrl: item.user?.avatarUrl,
          data: {
            currencyCode: item.prize?.currencyCode,
            slug: item.game?.slug,
            provider: item.type === 'MiniGame' ? 'Arena' : item.game?.provider,
            prizeType: item?.prize.type,
            type: item.game?.type,
            win: item.prize?.value,
            winBase: item.prize?.baseAmount,
            imageUrl: item.imageUrl,
            id: item.id,
            name: item.name,
          },
          prize: {
            ...item.prize,
            imageUrl: item.imageUrl,
          },
          isHouse: item.user?.isHouse || false,
        };
      });

      if (!this.isUserLogged) {
        return newTopWins.filter(game => game.data.prizeType !== 'NFT' && !game.user.isHouse);
      }

      return newTopWins;
    },
    getUsdVal() {
      return data => data.baseWin ?? this.rates[data.currencyCode].USD * data.win;
    },
    activeMode() {
      return this.modes.find(item => item.active);
    },
  },
  watch: {
    isUserLogged(newVal) {
      if (newVal) {
        this.modes[0].active = true;
        this.modes[1].active = true;
      }
      this.chooseToggleMode();
    },
  },
  created() {
    this.chooseToggleMode();
  },
  methods: {
    ...mapActions(useWebsocketStore, ['subscribe',]),
    async init() {
      this.liveWins = await this.loadLiveWins();

      this.subscriptions.forEach((topic) => {
        this.subscribe(topic);
      });

      this.websocketStore.$onAction(({ name, args, }) => {
        if (document.visibilityState !== 'visible' || !this.isVisible) { return; }

        if (name === 'handleMessage') {
          if (args[0].type === 'Activity') {
            useMessageHandler({ name, args, }, this.addItem, 'Activity', 'GameWin');
            useMessageHandler({ name, args, }, this.addItem, 'Activity', 'GameMultiWin');
          }
          if (args[0].type === 'Sweepstake:Winner') {
            useMessageHandler({ name, args, }, this.addItem, 'Sweepstake:Winner');
          }
        }
      });
    },
    addItem(payload) {
      if (payload.type === 'GameMultiWin') {
        const items = this.convertGameMultiWin(payload) || [];
        this.liveWins.unshift(...items);
      } else if (this.getUsdVal(payload.data) >= this.minWinUsd) {
        if (this.subCategory !== 'all' && payload.subCategory !== this.subCategory) { return; }
        this.liveWins.unshift(payload);
      }
      this.liveWins = this.liveWins.slice(0, this.maxWins);
    },
    convertGameMultiWin(payload) {
      const items = [];
      payload?.data?.winners?.forEach((winner, index) => {
        items.push({
          ...payload,
          type: 'GameWin',
          id: `${payload.id}-${index}`,
          displayName: winner.displayName,
          avatarUrl: winner.avatarUrl,
          country: winner.country,
          data: {
            id: payload.data?.id,
            name: payload.data?.name,
            slug: payload.data?.slug,
            type: payload.data?.type,
            imageUrl: payload.data?.imageUrl,
            win: winner.amount,
            currencyCode: winner.currencyCode,

          },
        });
      });
      return items;
    },
    async loadLiveWins() {
      await Promise.allSettled([this.fetchGameActivities(), this.fetchCompetitionWinners(),]);
      let liveWinsMerged = [...this.initialGameWinners, ...this.initialCompetitionWinners,];
      liveWinsMerged = _OrderBy(liveWinsMerged, ['timestamp',], ['desc',]).slice(0, this.maxWins);
      return liveWinsMerged;
    },
    async fetchGameActivities() {
      // subcategory if all then don't add subcategory

      try {
        let activities = await this.$api('/activity', {
          method: 'GET',
          params: {
            category: 'Games',
            take: 30,
            subCategory: this.subCategory === 'all' ? undefined : this.subCategory,
          },
        });
        activities = activities.filter(activity => ['GameWin', 'GameMultiWin',].includes(activity.type));
        activities.forEach((activity, index) => {
          if (activity.type === 'GameMultiWin') {
            const items = this.convertGameMultiWin(activity);
            activities.splice(index, 1, ...items);
          }
        });

        this.initialGameWinners = activities || [];
        return activities;
      } catch (err) {
        useNuxtApp().$rollbar.error('method failed', err);
      }
    },
    async fetchCompetitionWinners() {
      try {
        let winners = await this.$api('/sweepstake/winner', {
          method: 'GET',
          params: {
            take: this.loadMaxWins,
          },
        });
        winners = winners.map((winner) => {
          return {
            ...winner,
            timestamp: winner.date,
          };
        });
        this.initialCompetitionWinners = winners || [];
        return winners;
      } catch (err) {
        useNuxtApp().$rollbar.error('method failed', err);
      }
    },
    async fetchTopWinners(period) {
      let url = '/statistics/wins/top';
      if (['week', 'month', 'day',].includes(period)) {
        url = `/statistics/wins/top/${period}`;
      }
      try {
        let winners = await this.$api(url, {
          method: 'GET',
          params: {
            count: this.loadMaxWins,
          },
        });
        winners = winners.map((winner) => {
          return {
            ...winner,
            timestamp: winner.date,
          };
        });
        this.topWins = winners || [];
        return winners;
      } catch (err) {
        useNuxtApp().$rollbar.error('method failed', err);
      }
    },
    async toggleMode(id) {
      if (['top', 'month', 'week', 'day',].includes(id)) {
        await this.fetchTopWinners(id);
      } else if (!this.initiated && (id === 'live')) {
        await this.init();
        this.initiated = true;
      }

      this.modes.forEach((mode) => {
        mode.active = false;
      });
      this.modes.find(item => item.id === id).active = true;
    },
    chooseToggleMode() {
      if (!this.isUserLogged) {
        this.toggleMode(this.modes[1].id);
      } else if (this.placement === 'game') {
        this.toggleMode(this.modes[0].id);
      } else {
        this.toggleMode(this.activeMode.id);
      }
    },
    onVisibilityChange(isVisible) {
      this.isVisible = isVisible;
    },
  },
});
</script>
<style lang="scss" scoped>
.category-title {
  font-family: Inter;
  line-height: 28px;
  letter-spacing: 0em;
}
</style>
